<template>
  <b-container fluid="xxl">
    <b-form class="myprofile">
      <b-card
        bg-variant="light"
        class="mb-5"
        :title="$t('profile.my-profile')"
        footer-class="text-right"
        footer-bg-variant="light"
      >
        <b-row>
          <b-col>
            <b-form-group
              id="fieldset-1"
              label-for="input-1"
              :label="$t('profile.username')"
              label-cols="4"
              label-cols-lg="3"
              >{{ user.username }}</b-form-group
            >
            <b-form-group
              id="fieldset-2"
              :label="$t('profile.name')"
              label-for="input-2"
              label-cols="4"
              label-cols-lg="3"
            >
              <b-form-input
                id="input-2"
                autocomplete="name"
                v-model.trim="$v.user.fullName.$model"
                :state="validateUserFormInput('fullName')"
              />
            </b-form-group>
            <b-form-group
              id="fieldset-3"
              :label="$t('profile.e-mail')"
              label-for="input-3"
              label-cols="4"
              label-cols-lg="3"
              :invalid-feedback="$t('user.invalidFeedback-msg-email')"
            >
              <b-form-input
                id="input-3"
                autocomplete="email"
                type="text"
                maxlength="255"
                v-model.trim="$v.user.email.$model"
                :state="validateUserFormInput('email')"
              />
            </b-form-group>
            <b-form-group
              id="fieldset-4"
              :label="$t('profile.role')"
              label-for="input-4"
              label-cols="4"
              label-cols-lg="3"
              >{{ $t('user.roles.' + user.role.toLowerCase()) }}</b-form-group
            >
          </b-col>
          <b-col cols="3" class="text-right">
            <b-avatar size="150px" rounded="lg" variant="white">
              <v-gravatar :size="150" default-img="mm" :email="user.email" class="gravatar" />
            </b-avatar>
            <small id="emailHelp" class="form-text text-muted"
              >Avatar via <a href="https://gravatar.com" target="_blank">Gravatar</a></small
            >
          </b-col>
        </b-row>

        <template #footer>
          <b-button @click="saveUserProfile()" variant="primary" :disabled="$v.user.$anyError">{{
            $t('profile.submit')
          }}</b-button>
        </template>
      </b-card>
    </b-form>

    <b-form class="changePassword">
      <b-card
        bg-variant="light"
        :title="$t('profile.change-password')"
        footer-class="text-right"
        footer-bg-variant="light"
      >
        <b-form-group
          id="fieldset-5"
          v-bind:description="$t('profile.current-pw-text')"
          label-for="input-5"
          :label="$t('profile.current-pw')"
          label-cols="4"
          label-cols-lg="3"
        >
          <b-form-input
            id="input-5"
            type="password"
            v-model.trim="$v.password.currentPassword.$model"
            autocomplete="current-password"
            minlength="8"
            maxlength="200"
          />
        </b-form-group>
        <b-form-group
          id="fieldset-6"
          label-for="input-6"
          :label="$t('profile.new-pw')"
          label-cols="4"
          label-cols-lg="3"
          :invalid-feedback="$t('passwordReset.password-error')"
        >
          <b-form-input
            id="input-6"
            type="password"
            v-model.trim="$v.password.newPassword.$model"
            autocomplete="new-password"
            minlength="8"
            maxlength="200"
            :state="validatePassFormInput('newPassword')"
          />
        </b-form-group>
        <b-form-group
          id="fieldset-7"
          label-for="input-7"
          :label="$t('profile.conf-new-pw')"
          label-cols="4"
          label-cols-lg="3"
        >
          <b-form-input
            id="input-7"
            type="password"
            v-model.trim="$v.password.confNewPassword.$model"
            autocomplete="new-password"
            minlength="8"
            maxlength="200"
            :state="validatePassFormInput('confNewPassword')"
          />
        </b-form-group>

        <template #footer>
          <b-button @click="changePassword()" variant="primary" :disabled="$v.password.$invalid">{{
            $t('profile.change-password')
          }}</b-button>
        </template>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import { validationMixin } from 'vuelidate'
import { required, sameAs } from 'vuelidate/lib/validators'
import { GoldflamEmail, GoldflamPassword } from '@/util/VuelidateValidators'
import { cloneDeep as _cloneDeep } from 'lodash'
import { JsonWebToken } from '@/util/JsonWebToken'

export default {
  name: 'AccountProfile',
  mixins: [ApiMixin, NotificationMixin, validationMixin],
  data() {
    return {
      objectModels: {
        password: {
          currentPassword: '',
          newPassword: '',
          confNewPassword: '',
          type: 'PASSWORD_CHANGE'
        }
      },
      products: null,
      searchTerm: '',
      loading: false,
      searchTermNotfound: false,
      text: '',
      user: this.$store.getters.getCurrentUser,
      password: {},
      vacation: {
        start: null,
        end: null,
        confirmed: false
      }
    }
  },
  validations: {
    user: {
      fullName: {
        required
      },
      email: {
        required,
        GoldflamEmail
      }
    },
    password: {
      currentPassword: {
        required
      },
      newPassword: {
        required,
        GoldflamPassword
      },
      confNewPassword: {
        required,
        sameAsPassword: sameAs('newPassword')
      }
    }
  },
  created() {
    this.password = _cloneDeep(this.objectModels.password)
  },
  methods: {
    validateUserFormInput(prop) {
      const { $dirty, $invalid } = this.$v.user[prop]
      return $dirty ? !$invalid : null
    },
    validatePassFormInput(prop) {
      const { $dirty, $invalid } = this.$v.password[prop]
      return $dirty ? !$invalid : null
    },
    saveUserProfile() {
      let self = this
      this.putRequest(
        '/account/profile',
        self.user,
        new RequestConfig().onSuccess(res => {
          if (res) {
            self.$store.commit('setCurrentUser', res.data)
            self.displaySuccess(self.$t('profile.success-msg'))

            // save updated JWT in case security details changed
            if (res.headers.authorization) {
              self.handleJwtTokenChange(res.headers.authorization)
            }
          }
        })
      )
    },
    changePassword() {
      let self = this
      this.putRequest(
        '/account/password',
        self.password,
        new RequestConfig().onSuccess(res => {
          self.resetPassword()
          self.displaySuccess(self.$t('passwordReset.reset-successful'))

          // save updated JWT in case security details changed
          if (res && res.headers.authorization) {
            self.handleJwtTokenChange(res.headers.authorization)
          } else {
            self.displayError(this.$t('profile.jwt-error-msg'))
          }
        })
      )
    },
    resetPassword() {
      this.password = _cloneDeep(this.objectModels.password)
      this.$v.$reset()
    },
    handleJwtTokenChange(token) {
      console.log('JWT token changed. Re-submitting to Vuex store...')
      if (JsonWebToken.isValid(token)) {
        this.$store.commit('setToken', token)
        console.log('JWT token ist valid and was changed in the Vuex store...')
      } else {
        console.log('JWT token ist invalid')
        this.displayError(this.$t('profile.jwt-error-msg'))
        // TODO redirect to /logout or display a login modal to re-login
      }
    }
  }
}
</script>
